<template>
  <div class="home container">
    <configurations-view></configurations-view>
  </div>
</template>

<script>

import ConfigurationsView from '@/views/configurations/ConfigurationsView.vue';

export default {
  name: 'HomeView',
  components: {
    ConfigurationsView,
  },
  data() {
    return { visible: false };
  },
  methods: {
  },
  mounted() {},
};
</script>
