<template>
  <div class="data-source">
    <div class="data-source-navbar">
      <breadcrumb-view :items="breadcrumb"></breadcrumb-view>

      <principal-bar
        title="DataSources"
        :buttonsList="buttonsList"
      ></principal-bar>

    </div>
    <simple-table
      :showSearch="true"
      @viewRow="viewDataSource"
      @searchRow="searchDataSource"
      placeholderSearch="Search by dataSource name"
      :searchText="'Filter DataSource'"
      :data="dataSource"
      :tableColumns="columns"
      :loading="loading"
      :firstColumn="firstColumn"
      class="container"
    ></simple-table>

    <simple-paginator
      @currentChange="currentChange"
      @sizeChange="sizeChange"
      :currentPage="paginator.currentPage"
      :pageSize="paginator.pageSize"
      :total="paginator.total"
      :pagerCount="5"
      layout="total, sizes, prev, pager, next, jumper"
      class="container"
    ></simple-paginator>

    <data-source-modal
      v-if="showModalCreate"
      :showModal="showModalCreate"
      :state="state"
      :brandId="brandId"
      :id="dataSourceID"
      :title="title">
    </data-source-modal>

    <view-modal
      v-if="showModalView"
      :showModal="showModalView"
      :data="dataSourceItem">
    </view-modal>
  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex';
import ApiGEOLOCATION from '@/lib/ApiGEOLOCATION';
import Utils from '@/lib/Utils';
import SimpleTable from '@/components/Tables/SimpleTable.vue';
import PrincipalBar from '@/components/Bars/PrincipalBar.vue';
import DataSourceModal from '@/components/Modals/dataSources/DataSourceModal.vue';
import ViewModal from '@/components/Modals/ViewModal.vue';
import BreadcrumbView from '@/components/Bars/BreadcrumbView.vue';
import SimplePaginator from '@/components/Paginator/SimplePaginator.vue';

export default {
  name: 'DataSourceView',
  components: {
    BreadcrumbView,
    ViewModal,
    DataSourceModal,
    SimpleTable,
    PrincipalBar,
    SimplePaginator,
  },
  props: ['brandId'],
  data() {
    return {
      Utils,
      buttonsList: [
        {
          text: 'Create New',
          actionType: 'showModalCreate',
          icon: 'mdi-plus',
        },
      ],
      loading: true,
      showModalCreate: false,
      showModalEdit: false,
      showModalView: false,
      dataSource: [],
      dataSourceItem: {},
      brand: {},
      state: 'create',
      title: 'New DataSource',
      dataSourceID: null,
      breadcrumb: [
        {
          string: 'Configure DataSources',
          active: true,
        },
      ],
      columns: [
        {
          prop: 'name',
          label: 'DataSource Name',
          sortable: false,
          width: '250',
        },
        {
          prop: 'host',
          label: 'Host',
          sortable: false,
          width: '250',
        },
        {
          prop: 'user',
          label: 'User Name',
          sortable: false,
          width: '250',
        },
        {
          prop: 'createdAt',
          label: 'Created Date',
          sortable: false,
          format: 'fromNow',
        },
        {
          prop: 'actions',
          label: 'Actions',
          sortable: false,
          isActions: true,
          typeButtons: 'links',
          buttons: [],
          width: '130',
        },
        {
          prop: 'admin',
          label: 'Admin',
          sortable: false,
          isActions: true,
          typeButtons: 'icons',
          dropdown: [],
          width: '130',
        },
      ],
      firstColumn: {
        show: true,
        icon: 'mdi-chart-box-outline',
      },
      paginator: {
        currentPage: 0,
        pageSize: 10,
        total: 0,
        page: 1,
      },
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isValidPermission']),
    brandName() {
      const { brand } = this;
      const result = brand.name;
      return result;
    },
  },
  methods: {
    getAll(search = null) {
      this.dataSource = [];
      this.loading = true;
      const paginate = `page_size=${this.paginator.pageSize}&page=${this.paginator.page}`;

      let url = `/datasources?${paginate}`;

      if (search) {
        url = `/datasources?${paginate}&search=${search}`;
      }
      ApiGEOLOCATION.get(url).then((r) => {
        this.dataSource = Object.values(r.data);
        if (r.paginator.total_pages) {
          this.paginator.currentPage = r.paginator.page;
          this.paginator.page_size = r.paginator.page_size;
          this.paginator.total = r.paginator.count;
        }
        this.loading = false;
      }).catch((e) => {
        console.log(e);
        this.loading = false;
      });
    },
    closeModal() {
      this.showModalView = false;
      this.showModalEdit = false;
      this.showModalCreate = false;
    },
    openModalCreate() {
      this.showModalCreate = true;
      this.state = 'create';
      this.title = 'New DataSource';
    },
    viewDataSource(row) {
      const dataSourceItem = [];
      this.showModalView = true;
      dataSourceItem.push({
        name: row.name,
        host: row.host,
        user: row.user,
        database: row.database,
      });
      const [arr] = dataSourceItem;
      this.dataSourceItem = arr;
      // console.log('-> row', row);
    },
    editDataSource(data) {
      if (data.type === 'DataSources') {
        this.showModalCreate = true;
        this.state = 'update';
        this.title = 'Update DataSource';
        this.dataSourceID = data.id;
      }
    },
    async hideDataSource(id) {
      try {
        const r = await ApiGEOLOCATION.put(`/datasources/${id}`, { enabled: true });
        if (r.success) {
          this.getAll();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async deleteDataSource(id) {
      try {
        const r = await ApiGEOLOCATION.delete(`/datasources/${id}`);
        if (r.success) {
          this.getAll();
        }
      } catch (error) {
        console.error(error);
      }
    },
    searchDataSource(string) {
      this.getAll(string);
    },
    validatePermissions() {
      let actions = '';

      actions = this.columns.findIndex((btn) => btn.prop === 'actions');
      this.columns[actions].buttons.push({
        text: 'View',
        action: 'view',
      });

      actions = this.columns.findIndex((btn) => btn.prop === 'admin');
      this.columns[actions].dropdown.push({
        text: 'Edit',
        action: 'edit',
      });

      // actions = this.columns.findIndex((btn) => btn.prop === 'admin');
      // this.columns[actions].dropdown.push({
      //   text: 'Hide',
      //   action: 'hide',
      // });

      // actions = this.columns.findIndex((btn) => btn.prop === 'admin');
      // this.columns[actions].dropdown.push({
      //   text: 'Delete',
      //   action: 'delete',
      // });
    },
    currentChange(val) {
      this.paginator.page = val;
      this.getAll();
    },
    sizeChange(val) {
      this.paginator.pageSize = val;
      this.paginator.handlesSizeChange = true;
      this.getAll();
    },
  },
  async mounted() {
    this.getAll();

    this.$root.$on('closeModal', this.closeModal);
    this.$root.$on('openModalCreate', this.openModalCreate);
    this.$root.$on('reloadDataSources', this.getAll);

    // TABLE EMITS
    this.$root.$on('editRow', this.editDataSource);
    this.$root.$on('hideRow', this.hideDataSource);
    this.$root.$on('deleteRow', this.deleteDataSource);

    this.validatePermissions();
  },
};
</script>

<style lang="scss">

  .data-source-navbar{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto ;
  background: rgba(63, 181, 229, 0.08);
  height: 145px;
  align-content: center;
  padding: 0 30px;

  @media (min-width: 720px){
    padding: 0 90px;
    grid-template-rows: auto;
    grid-template-columns: auto auto ;
  }

  .principal-bar{
    justify-self: end;
    column-gap: 10px !important;
    @media (min-width: 720px){
      column-gap: 20px;
    }

    .title{
      display: block;
      @media (min-width: 720px){
        display: none;
      }

      h1{
        font-size: 20px;
      }
    }
  }

}
</style>
