import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: () => ({
    user: null,
    token: null,
    refreshToken: null,
    authExternal: null,
  }),
  getters: {
    isLoggedIn(state) {
      return !!state.token;
    },
    isRefreshToken(state) {
      return !!state.refreshToken;
    },
    isAuthExternal(state) {
      return !!state.authExternal;
    },
    isValidPermission: (state) => (identifier) => {
      if (!state.user?.permissions) {
        return false;
      }

      return state.user.permissions.includes(identifier);
    },
  },
  mutations: {
    setUser(state, user) {
      if (state.user && user !== null) {
        state.user = { ...state.user, ...user };
      } else {
        state.user = user;
      }
    },
    setToken(state, token) {
      state.token = token;
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
    },
    setAuthExternal(state, authExternal) {
      state.authExternal = authExternal;
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin],
});
