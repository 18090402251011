<template>
  <div class="settings-config">
    <div class="elements">
      <div class="blocks-config">
        <div class="config" >
          <span class="demo-input-label">Data Source</span>
          <el-select
            v-model="data.dataSource"
            @change="handleGetTables(data)"
            clearable
            filterable
            placeholder="Select Data Source">
              <el-option
                v-for="item in dataSources"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
          </el-select>

          <span class="demo-input-label">Table</span>
          <el-select
            v-model="data.table"
            @change="handleGetColumns(data)"
            clearable
            filterable
            placeholder="Select Table">
              <el-option
                v-for="item in tables"
                :key="item.tables"
                :label="item.tables"
                :value="item.tables">
              </el-option>
          </el-select>

          <template v-if="showMap">
            <span class="demo-input-label">Map Columns</span>
            <div class="map-column">
              <span>ip</span>
              <el-select
                v-model="data.ip"
                @change="handleChangeValue()"
                clearable
                filterable
                placeholder="Select Column">
                  <el-option
                    v-for="item in columns"
                    :key="item.Field"
                    :label="item.Field"
                    :value="item.Field">
                  </el-option>
              </el-select>
            </div>

            <div class="map-column">
              <span>country_name</span>
              <el-select
                v-model="data.country_name"
                @change="handleChangeValue()"
                clearable
                filterable
                placeholder="Select Column">
                  <el-option
                    v-for="item in columns"
                    :key="item.Field"
                    :label="item.Field"
                    :value="item.Field">
                  </el-option>
              </el-select>
            </div>

            <div class="map-column">
              <span>country_code</span>
              <el-select
                v-model="data.country_code"
                @change="handleChangeValue()"
                clearable
                filterable
                placeholder="Select Column">
                  <el-option
                    v-for="item in columns"
                    :key="item.Field"
                    :label="item.Field"
                    :value="item.Field">
                  </el-option>
              </el-select>
            </div>

            <div class="map-column">
              <span>city</span>
              <el-select
                v-model="data.city"
                @change="handleChangeValue()"
                clearable
                filterable
                placeholder="Select Column">
                  <el-option
                    v-for="item in columns"
                    :key="item.Field"
                    :label="item.Field"
                    :value="item.Field">
                  </el-option>
              </el-select>
            </div>

            <div class="map-column">
              <span>city_code</span>
              <el-select
                v-model="data.city_code"
                @change="handleChangeValue()"
                clearable
                filterable
                placeholder="Select Column">
                  <el-option
                    v-for="item in columns"
                    :key="item.Field"
                    :label="item.Field"
                    :value="item.Field">
                  </el-option>
              </el-select>
            </div>

            <div class="map-column">
              <span>state_name</span>
              <el-select
                v-model="data.state_name"
                @change="handleChangeValue()"
                clearable
                filterable
                placeholder="Select Column">
                  <el-option
                    v-for="item in columns"
                    :key="item.Field"
                    :label="item.Field"
                    :value="item.Field">
                  </el-option>
              </el-select>
            </div>

            <div class="map-column">
              <span>state_code</span>
              <el-select
                v-model="data.state_code"
                @change="handleChangeValue()"
                clearable
                filterable
                placeholder="Select Column">
                  <el-option
                    v-for="item in columns"
                    :key="item.Field"
                    :label="item.Field"
                    :value="item.Field">
                  </el-option>
              </el-select>
            </div>

            <div class="map-column">
              <span>lct_isp</span>
              <el-select
                v-model="data.lct_isp"
                @change="handleChangeValue()"
                clearable
                filterable
                placeholder="Select Column">
                  <el-option
                    v-for="item in columns"
                    :key="item.Field"
                    :label="item.Field"
                    :value="item.Field">
                  </el-option>
              </el-select>
            </div>

            <div class="map-column">
              <span>lct_latitude</span>
              <el-select
                v-model="data.lct_latitude"
                @change="handleChangeValue()"
                clearable
                filterable
                placeholder="Select Column">
                  <el-option
                    v-for="item in columns"
                    :key="item.Field"
                    :label="item.Field"
                    :value="item.Field">
                  </el-option>
              </el-select>
            </div>

            <div class="map-column">
              <span>lct_longitude</span>
              <el-select
                v-model="data.lct_longitude"
                @change="handleChangeValue()"
                clearable
                filterable
                placeholder="Select Column">
                  <el-option
                    v-for="item in columns"
                    :key="item.Field"
                    :label="item.Field"
                    :value="item.Field">
                  </el-option>
              </el-select>
            </div>

            <div class="map-column">
              <span>lct_ip_name</span>
              <el-select
                v-model="data.lct_ip_name"
                @change="handleChangeValue()"
                clearable
                filterable
                placeholder="Select Column">
                  <el-option
                    v-for="item in columns"
                    :key="item.Field"
                    :label="item.Field"
                    :value="item.Field">
                  </el-option>
              </el-select>
            </div>

            <div class="map-column">
              <span>lct_ip_type</span>
              <el-select
                v-model="data.lct_ip_type"
                @change="handleChangeValue()"
                clearable
                filterable
                placeholder="Select Column">
                  <el-option
                    v-for="item in columns"
                    :key="item.Field"
                    :label="item.Field"
                    :value="item.Field">
                  </el-option>
              </el-select>
            </div>
          </template>

          <template v-else>
            <span class="demo-input-label">Ip Column</span>
            <el-select
              v-model="data.column"
              @change="handleChangeValue()"
              clearable
              filterable
              placeholder="Select Ip Column">
                <el-option
                  v-for="item in columns"
                  :key="item.Field"
                  :label="item.Field"
                  :value="item.Field">
                </el-option>
            </el-select>

            <span class="demo-input-label">Column to check if Ip is already processed</span>
            <el-select
              v-model="data.columnCheck"
              @change="handleChangeValue()"
              clearable
              filterable
              placeholder="Select Column">
                <el-option
                  v-for="item in columns"
                  :key="item.Field"
                  :label="item.Field"
                  :value="item.Field">
                </el-option>
            </el-select>
          </template>

          <!-- <span class="demo-input-label">Query</span>
          <el-input
            @change="handleChangeValue()"
            type="textarea"
            :rows="4"
            placeholder="Please input query"
            v-model="data.query">
          </el-input> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiGEOLOCATION from '@/lib/ApiGEOLOCATION';

export default {
  name: 'SettingsConfig',
  props: ['value', 'showMap'],
  data() {
    return {
      currentVal: null,
      dataSources: {},
      tables: [],
      columns: [],
      data: {
        dataSource: '',
        table: '',
        column: '',
        query: '',
      },
    };
  },
  watch: {
    currentVal(newValue) {
      this.$emit('input', newValue);
    },
    data(newValue) {
      this.currentVal = JSON.stringify(newValue);
    },
    'data.dataSource': {
      async handler(value) {
        if (value) {
          const conf = {
            dataSource: value,
          };
          this.tables = await this.getTables(conf);
        }
      },
      deep: true,
    },
    'data.table': {
      async handler(value) {
        if (value) {
          const conf = {
            dataSource: this.data.dataSource,
            table: value,
          };
          this.columns = await this.getColumns(conf);
        }
      },
      deep: true,
    },
  },
  methods: {
    async getDataSources() {
      try {
        const res = await ApiGEOLOCATION.get('/datasources');
        return res.data ?? [];
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    handleChangeValue() {
      this.currentVal = JSON.stringify(this.data);
    },
    async handleGetTables(conf) {
      this.tables = {};
      this.data.table = '';
      this.columns = {};
      this.data.column = '';
      if (conf?.dataSource) {
        this.tables = await this.getTables(conf);
      } else {
        this.tables = {};
        this.data.table = '';
      }
      this.handleChangeValue();
    },
    async handleGetColumns(conf) {
      if (conf?.table) {
        this.columns = await this.getColumns(conf);
      } else {
        this.columns = null;
        this.data.column = '';
      }
      this.handleChangeValue();
    },
    async getTables(conf) {
      try {
        const res = await ApiGEOLOCATION.get(`/datasources/${conf.dataSource}/tables`);
        return res.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async getColumns(conf) {
      try {
        const res = await ApiGEOLOCATION.get(`/datasources/${conf.dataSource}/table/${conf.table}/columns`);
        return res.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
  },
  async mounted() {
    this.dataSources = await this.getDataSources();

    const { value } = this;
    this.currentVal = value;
    if (value) {
      this.data = JSON.parse(value);
    }
  },
};
</script>

<style lang="scss">

  .map-column{
    display: grid;
    grid-template-columns: auto 70%;
  }
  .settings-config {
    .add-elements {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 5px;
    }
    .elements {
      box-shadow: 0 0 12px #e6e6e6;
      border-radius: 6px;
      .blocks-config {
        padding-bottom: 5px;
        .config {
          display: grid;
          row-gap: 5px;
          padding: 15px;
        }
      }
    }
  }
</style>
