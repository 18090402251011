<template>
  <div class="view-modal">
    <el-dialog
      :title="data.name"
      width="30%"
      :visible.sync="dialogVisible"
      :before-close="handleClose">
        <el-descriptions
          direction="vertical"
          :column="1"
          border
        >
          <el-descriptions-item
            v-for="(item, key, i) in data"
            :key="`item-${i}`"
            :label="key">
            {{ item }}
          </el-descriptions-item>
        </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ViewModal',
  props: ['showModal', 'data'],
  data() {
    return {
      dialogVisible: this.showModal,
    };
  },
  methods: {
    handleClose() {
      this.$root.$emit('closeModal');
    },
  },
};
</script>

<style lang="scss">
.el-descriptions-item__label {
  text-transform: capitalize;
  font-weight: bold;
}

.el-dialog{
    max-height: 800px !important;
    overflow: auto !important;
    height: auto;
    margin-top: 8vh !important;
    border-radius: 8px;
    padding: 40px 40px 26px 40px;
    @media (min-width: 720px){
      padding: 20px 20px 46px 20px;
    }
  }
  .el-dialog__header{
    padding: 0 20px 0 20px !important;
  }
  .el-dialog__body{
    padding: 0;
  }
</style>
