import Vue from 'vue';
import VueRouter from 'vue-router';
import SSOApi from '@debrain/sso-api-integration';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/HomeView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/components/AuthView.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "Profile" */ '@/views/user/ProfileView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/datasources',
    name: 'DataSources',
    component: () => import(/* webpackChunkName: "DataSources" */ '@/views/dataSource/DataSourceView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/configurations',
    name: 'Configurations',
    component: () => import(/* webpackChunkName: "DataSources" */ '@/views/configurations/ConfigurationsView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/token',
    name: 'Token',
    component: () => import(/* webpackChunkName: "Token" */ '@/components/AuthToken.vue'),
    props: (route) => ({ params: { ...route.query, ...route.params } }),
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue'),
  },

];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const session = store.getters.isLoggedIn;

    if (!session || session === null) {
      SSOApi.configure({
        appId: process.env.VUE_APP_APP_ID,
        apiKey: process.env.VUE_APP_API_KEY,
        baseSSOURL: process.env.VUE_APP_BASE_SSO_URL,
        baseSSOAPIURL: process.env.VUE_APP_BASE_SSO_API_URL,
      });

      SSOApi.redirectToLoginURL({
        state: window.btoa(from.fullPath),
        redirecturl: process.env.VUE_APP_REDIRECT_URL,
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
